var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('PageHeader',{attrs:{"title":_vm.title,"items":_vm.items}}),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticStyle:{"display":"none","margin":"0 auto"},style:({width:_vm.moduleType=='1'?'1103px':'780px'}),attrs:{"id":"pdf"}}),_c('div',{staticClass:"outerCotent mt-3"},[_c('div',{staticClass:"style-set-box h-100"},[_c('el-row',{staticClass:"container",attrs:{"id":"gradePrint"}},[_c('el-col',{ref:"main",staticClass:"style-right h-100",style:({ backgroundImage: 'url(' + _vm.imgUrl + ')' }),attrs:{"span":24,"id":"mians"}},[_c('div',{staticClass:"w100"},_vm._l((_vm.ruleList),function(item,i){return _c('div',{key:i,staticClass:"gzContent nobgColor",attrs:{"id":'nobgColor' + i}},[_c('p',{staticClass:"text-center  text-dark  pb-2",staticStyle:{"font-weight":"600","font-size":"16px"}},[_vm._v(" "+_vm._s(item.mbmc)+" ")]),_c('div',{staticClass:"contentMain",staticStyle:{"font-family":"微软雅黑,华文细黑,宋体,黑体,arial,Hiragino Sans GB,Microsoft Yahei,Tahoma,Arial,Helvetica,STHeiti"},domProps:{"innerHTML":_vm._s(item.mbnr)}})])}),0),_c('div',{staticClass:"zsContent"},[_c('div',{staticClass:"w100 cjd_title",staticStyle:{"white-space":"pre-wrap","font-size":"16px","font-weight":"600"}},[_vm._v(" "+_vm._s(_vm.examForm.zkzmc)+" ")]),_c('div',{staticClass:" exam-form"},[_c('el-row',{staticClass:"border-diff top-height"},[_c('el-col',{staticClass:"guding-top"},[_c('div',{ref:"list1Container",staticClass:"flexList guding-col",staticStyle:{"min-height":"40px"},attrs:{"list":_vm.list2,"group":"people"}},_vm._l((_vm.list2),function(element){return _c('div',{key:element.sid,staticClass:"flexList msgLabel",class:[
                          'w' + element.zdbl,
                          element.txlx == 'textarea' ? 'msgtextarea' : '' ]},[_c('div',{staticClass:"style-name mr-3"},[_vm._v(" "+_vm._s(element.zdymc ? element.zdymc : element.zdmc)+"： ")]),_c('div',{staticClass:"style-val flexList"},[_vm._v(" "+_vm._s(_vm.examineeData[element.zddm])+" ")])])}),0)]),_c('el-col',{staticClass:"guding-img"},[_c('img',{staticStyle:{"width":"96px","height":"120px"},attrs:{"src":'data:image/jpeg;base64' +_vm.zpdz ? _vm.zpdz : _vm.defaultZpdz,"alt":""}})]),_c('el-col',[_c('div',{staticClass:"flexList fs-xs less-width",staticStyle:{"width":"99%","flex-wrap":"wrap"},attrs:{"list":_vm.list3,"group":"people"}},_vm._l((_vm.list3),function(element){return _c('div',{key:element.sid,staticClass:"flexList msgLabel",class:[
                          'w' + element.zdbl,
                          element.txlx == 'textarea' ? 'msgtextarea' : '' ]},[_c('div',{staticClass:"style-name mr-2"},[_vm._v(" "+_vm._s(element.zdymc ? element.zdymc : element.zdmc)+"： ")]),_c('div',{staticClass:"style-val flexList"},[_vm._v(" "+_vm._s(_vm.examineeData[element.zddm])+" ")])])}),0)])],1),_c('el-row',[_c('table',{staticClass:"table  zkzTable mt-3"},[_c('thead',_vm._l((_vm.list4),function(obj){return _c('th',{key:obj.sid,style:({
                          width:
                            (_vm.sumWidth && _vm.sumWidth != 0
                              ? (obj.zdbl / _vm.sumWidth).toFixed(3) * 100
                              : obj.zdbl) + '%',
                        })},[_vm._v(" "+_vm._s(obj.zdmc)+" ")])}),0),(_vm.subVals.length)?_c('tbody',_vm._l((_vm.subVals),function(value,index){return _c('tr',{key:index},_vm._l((_vm.list4),function(obj){return _c('td',{key:obj.sid},[_vm._v(" "+_vm._s(value[obj.zddm])+" ")])}),0)}),0):_vm._e()])])],1),_c('div',{staticClass:"w100  mb-3"},[_c('div',{staticClass:"text-dark  font-size-12",staticStyle:{"white-space":"pre-wrap"}},[_vm._v(" "+_vm._s(_vm.examForm.zkzzysx)+" ")])]),_c('div',{staticClass:"w-100 code-img"},[(_vm.ticketData.sfxsewm)?_c('img',{staticStyle:{"width":"100px!important","height":"100px"},attrs:{"src":'data:image/jpeg;base64' +_vm.qrcode ? _vm.qrcode : '',"alt":""}}):_vm._e()])])])],1),_vm._l((_vm.zkzcnsInfoList),function(item,i){return _c('el-row',{key:i,staticClass:"zkzcnsList container",attrs:{"id":item.mbbm}},[_c('div',{staticClass:"w100"},[_c('div',{staticClass:"gzContent nobgColor"},[_c('p',{staticClass:"text-center font-size-20 text-dark",staticStyle:{"font-weight":"600","font-size":"16px"}},[_vm._v(" "+_vm._s(item.mbmc)+" ")]),_c('div',{staticClass:"contentMain",staticStyle:{"font-family":"微软雅黑,华文细黑,宋体,黑体,arial,Hiragino Sans GB,Microsoft Yahei,Tahoma,Arial,Helvetica,STHeiti"},domProps:{"innerHTML":_vm._s(item.mbnr)}})])])])})],2)]),_c('div',{staticClass:"mt-3 flexList"},[_c('button',{staticClass:"btn btn-info flexList h30",attrs:{"type":"button"},on:{"click":function($event){return _vm.printOrPreview(true)}}},[_c('img',{staticClass:"mr-2",attrs:{"src":require("@/assets/images/person/file-pdf.png")}}),_vm._v("下载电子准考证 ")])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }