<style type="text/css" scoped>
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/admin.css";
  @import "~@/assets/css/ticket-print.css";
  html,body{
    font-size: 16px;
  }
  /deep/.nobgColor p {
    background-color: transparent !important;
  }

  /deep/.outerCotent p,
  /deep/#pdf p {
    margin-bottom: 0;
  }

  /deep/#nobgColor p {
    background-color: transparent !important;
  }

  /deep/ p {
    margin: unset;
  }
</style>
<script>
    import Layout from "@/views/layouts/main";
    import PageHeader from "@/components/page-header";
    import $ from "jquery";
    import {
        getTicket,
        getPrintTemplate,
        getNoticeDetails,
        getZkzInfo,
        getTemplateInfo,
        getZkzSubject,
        getCodeByZkz
    } from "@/api/grade/grade.js";
    import {
        getMbInfo
    } from "@/api/examination";
    import {
        format
    } from "@/libs/common";
    import {noChange} from "@/assets/js/nochange.js"

    export default {
        name: "clone-on-control",
        display: "Clone on Control",
        instruction: "Press Ctrl to clone element from list 1",
        order: 4,
        components: {
            Layout,
            PageHeader,
        },
        data() {
            return {
                title: "准考证单样式 ",
                items: [{
                    text: "用户首页",
                    href: "/admin",
                },
                    {
                        text: "准考证单样式预览",
                        active: true,
                    },
                ],
                query: {},
                list2: [],
                list3: [],
                list4: [],
                sid: "",
                subVals: {},
                form: {},
                isShowZp: true,
                zpdz: "",
                mbh: "",
                mbmc2: "",
                imgUrl: "",
                ksbmbh: "",
                sfzjh: "",
                moduleType: 0,
                defaultZpdz: require("@/assets/images/person/person-none.png"),
                listChoose: [],
                examineeData: {},
                examForm: {
                    zkzmc: "",
                    zkzzysx: "",
                    zkzbzxx: "",
                },
                ruleList: [], //选择的规则模板
                zkzcnsInfoList: [], //准考证承诺书
                zkzgzcnhList: [], //准考证承诺书号数组
                sumWidth: 0, //表格宽度总和
                pageNum: 0,
                pageHeight: 1103,
                pdfHeight: 0,
                pdfNum: 1,
                printShow: false,
               qrcode:"",
                ticketData: {},
            };
        },
        methods: {
            //下载准考证单
            printOrPreview(flag) {
                this.zkzgzcnhList.forEach((v) => {
                    this.toPdfHtml("pdf", v + "1", v, false);
                });
                $("#pdf").css("display", "");
                // flag为真是打印
                let moduleType;
                moduleType = this.moduleType;
                if (moduleType == 1) {
                    //横版
                    this.choosePrintPdf(flag, moduleType);
                } else {
                    moduleType = 0;
                    this.choosePrintPdf(flag, moduleType);
                }
            },
            choosePrintPdf(flag, type) {
                let ids = [];
                this.zkzcnsInfoList.forEach((v) => {
                    ids.push(v.mbbm + "1");
                });
                let str = ids.join(",") + ",zsInfo";
                this.ExportSavePdf("准考证", str, type, flag, "pdf");
            },
            // 渲染创建好的模板
            getTemplateList(sMid) {
                this.list2 = [];
                this.list3 = [];
                this.list4 = [];
                let func;
                let param;
                if (sMid) {
                    func = getPrintTemplate;
                    param = sMid;
                    func(this.query.mbh).then((res1) => {
                        if (res1.status) {
                            this.ticketData = res1.data;
                            let imgid = res1.data.cjbjwj;
                            if (imgid) {
                                this.getFileUrl(imgid, true).then((res) => {
                                    if (res.status) {
                                        this.imgUrl = res.data;
                                    }
                                });
                            }
                            if (!res1.data.sfxszp) {
                                this.isShowZp = false;
                                this.$nextTick(() => {
                                    this.hiddenZp();
                                });
                            } else {
                                this.isShowZp = true;
                                this.hiddenZp();
                            }
                            if (res1.data.sfhxbj) {
                                this.moduleType = 1;
                                this.pageHeight = Math.floor((1103 / 297) * 210);
                                $("#pdf").css("width", "1103");
                            } else {
                                this.moduleType = 0;
                            }

                            res1.data.printTemplateFieldBeanList.forEach((item) => {
                                if (this.examForm.zpysyq != 4) {
                                    if (item.zdfl == 1) {
                                        this.list2.push(item);
                                    } else if (item.zdfl == 2) {
                                        this.list3.push(item);
                                    } else if (item.zdfl == 3) {
                                        this.list4.push(item);
                                    }
                                } else {
                                    this.list3.push(item);
                                }
                            });
                            this.getTableRate();

                            return;
                        }
                    });
                }
            },
            getDetail(sid) {
                getZkzInfo(sid).then((res) => {
                    if (res.status) {
                        this.examForm = res.data;
                        this.mbmc2 = res.data.zkzmc;
                        let mbbm = res.data.zkzsmh;

                        if (this.query.mbh) {
                            this.getTemplateList(this.query.mbh);
                        }
                        this.getInfo();
                        if (mbbm) {
                            let newmbbm = mbbm.split(",");
                            const list = [];
                            newmbbm.forEach((v, i) => {
                                list[i] = getMbInfo(v);
                            });
                            Promise.all(list).then((values) => {
                                values.forEach((k) => {
                                    this.ruleList.push({
                                        mbmc: k.data.mbmc,
                                        mbnr: k.data.mbnr,
                                    });
                                });
                            });
                        }
                        if (res.data.zkzgzcnh) {
                            this.zkzgzcnhList = res.data.zkzgzcnh.split(",");
                            this.zkzgzcnhList.forEach((cnsh) => {
                                getMbInfo(cnsh).then((res) => {
                                    if (res.status) {
                                        let text = res.data.mbnr;
                                        text = text.replace(/{XM}/g, this.userInfo.xm);
                                        text = text.replace(/{ZJLX}/g, this.userInfo.sfzjlxmc);
                                        text = text.replace(/{SFZH}/g, this.userInfo.sfzjh);
                                        text = text.replace(
                                            /{RQ}/g,
                                            format(new Date(), "yyyy年MM月dd日")
                                        );
                                        res.data.mbnr = text;
                                        this.zkzcnsInfoList.push(res.data);
                                    }
                                });
                            });
                        }
                    }
                });
            },
            isHidden() {
                this.isShowZp = !this.isShowZp;
                this.hiddenZp();
            },
            //显示隐藏照片
            hiddenZp() {
                if (this.isShowZp == false) {
                    $(".guding-top").css("width", "99%");
                    $(".guding-top").addClass("border-right");
                    $(".guding-img").css("display", "none");
                    $(".guding-col").addClass("less-width");
                } else {
                    $(".guding-img").css("display", "flex");
                    $(".guding-top").css("width", "80%");
                    $(".guding-top").removeClass("border-right");
                    $(".guding-col").removeClass("less-width");
                }
            },
            // 准考证记录
            getInfo() {
                getZkzSubject({
                    zkzkid: this.query.sid,
                    zkzyxz: false,
                }).then((res) => {
                    if (res.status) {
                        var item = res.data;
                        this.subVals = item;
                        this.examineeData = res.data[0];
                        let userZpdz = res.data[0].zpdz;
                        let zkzkid = this.query.sid;
                        this.sfzjh = res.data[0].sfzjhEnc;
                        let fileId = "zkz_" + zkzkid + "_" + this.userInfo.sfzjh;
                        if (userZpdz) {
                            //获取照片地址
                            this.getFileUrl(userZpdz, true).then((res) => {
                                if (res.status) {
                                    this.zpdz = res.data;
                                }
                            });
                        } else {
                            this.getFileUrl(fileId, true).then((res) => {
                                if (res.status) {
                                    this.zpdz = res.data;
                                }
                            });
                        }
                    }
                });
            },
            getTableRate() {
                // 获取表格字段占比
                let sum = 0;
                let arr = this.list4;
                for (var i = 0; i < arr.length; i++) {
                    sum += arr[i].zdbl;
                }
                if (sum > 100) {
                    this.sumWidth = sum;
                }
                setTimeout(() => {
                    this.pageSign();
                    this.toPdfHtml("pdf", "zsInfo", "gradePrint", true);
                }, 400);
            },
            pageSign() {
                let height = $(".outerCotent").height();
                this.pageNum = parseInt(height / this.pageHeight);
            },
            toPdfHtml(id, id1, id2, zsAdd) {
                if ($("#" + id1).length > 0) {
                    return false
                }
                this.pdfNum = 1;
                this.pdfHeight = 0;
                $("#" + id).append("<div id='" + id1 + "'></div>");
                $("#" + id1).append(
                    "<div class='pdf' id='" +
                    id1 +
                    "1' page='1' style='height:" +
                    this.pageHeight +
                    "px'></div>"
                );
                // 考试规则
                $("#" + id2 + " .gzContent").each((i, item) => {
                    // 标题
                    $(item)
                        .children("p")
                        .each((i, item1) => {
                            this.addItem(item1, $(item1).outerHeight(), id1);
                        });
                    // 内容
                    $(item)
                        .find(".contentMain")
                        .children("p")
                        .each((i, item1) => {
                            $(item1)
                                .children("span")
                                .css("font-family", '"simhei", "华文仿宋"');
                            $(item1).css("font-family", '"simhei", "华文仿宋"');
                            this.addItem(item1, $(item1).outerHeight(), id1);
                        });
                });
                // 准考证内容
                if (zsAdd) {
                    let zsItem = $("#" + id2 + " .zsContent")[0];
                    this.addItem(zsItem, $(zsItem).outerHeight(), id1, this.imgUrl);
                }
            },
            addItem(item, height, id, imgurl) {
                let paddingHeight = 60;
                height = Number(height);
                // 当前pdf高度大于0，且未添加新对象高度前后的页数不一致则换页
                if (
                    this.pdfHeight > 0 &&
                    parseInt((this.pdfHeight + paddingHeight) / this.pageHeight) !=
                    parseInt((this.pdfHeight + height + paddingHeight) / this.pageHeight)
                ) {
                    // pdf页数加1
                    this.pdfNum += 1;
                    this.pdfHeight = (this.pdfNum - 1) * this.pageHeight;
                    $("#" + id).append(
                        "<div class='pdf' id='" +
                        id +
                        this.pdfNum +
                        "' page='" +
                        this.pdfNum +
                        "'style='height:" +
                        this.pageHeight +
                        "px'></div>"
                    );
                }
                this.pdfHeight += height;
                // 将内容放入div中
                $(item)
                    .clone()
                    .appendTo($("#" + id + this.pdfNum));
                // $("#" + id + " .pdf").css("border", "1px #000000 solid");
                $("#" + id + " .pdf").css("padding", "30px 25px");
                $("#" + id + " .pdf").css("color", "black");
                if (imgurl) {
                    $("#" + id + " .pdf").css("background-image", "url(" + imgurl + ")");
                }
            },
            getCodeByZkz() {
                getCodeByZkz({zkzkid: this.query.sid}).then(res => {
                    if (res.status){
                        this.qrcode = res.data.qrcode
                    }
                })
            },
        },

        mounted() {
            if (this.query.sid) {
                this.getDetail(this.query.sid);
                this.getCodeByZkz()

            }
        },
        created() {
            let data = localStorage.getItem("userInfo");
            this.userInfo = JSON.parse(data);
            this.query = this.$route.query ? this.$route.query : ""; //准考证库ID
            this.$nextTick(() => {
                noChange()
            });
        },
    };
</script>
<template>
    <Layout>
        <PageHeader :title="title" :items="items"/>
        <div class="card">
            <div class="card-body">
                <div id="pdf" style="display: none;margin: 0 auto"
                     :style="{width:moduleType=='1'?'1103px':'780px'}"></div>
                <div class="outerCotent mt-3">
                    <div class="style-set-box h-100">
                        <el-row id="gradePrint" class="container" style="">
                            <el-col :span="24" ref="main" id="mians" class="style-right h-100"
                                    :style="{ backgroundImage: 'url(' + imgUrl + ')' }">
                                <div class="w100">
                                    <div :id="'nobgColor' + i" class="gzContent nobgColor" v-for="(item, i) in ruleList"
                                         :key="i">
                                        <p class="text-center  text-dark  pb-2"
                                           style="font-weight: 600; font-size: 16px">
                                            {{ item.mbmc }}
                                        </p>
                                        <div v-html="item.mbnr" class="contentMain"
                                             style="font-family:微软雅黑,华文细黑,宋体,黑体,arial,Hiragino Sans GB,Microsoft Yahei,Tahoma,Arial,Helvetica,STHeiti"></div>
                                    </div>
                                </div>
                                <div class="zsContent">
                                    <div class="w100 cjd_title"
                                         style="white-space: pre-wrap;font-size: 16px;font-weight: 600;">
                                        {{ examForm.zkzmc }}
                                    </div>

                                    <div class=" exam-form">
                                        <el-row style="" class="border-diff top-height">
                                            <el-col class="guding-top">
                                                <div ref="list1Container" class="flexList guding-col" :list="list2"
                                                     group="people"
                                                     style="min-height: 40px">
                                                    <div class="flexList msgLabel" :class="[
                              'w' + element.zdbl,
                              element.txlx == 'textarea' ? 'msgtextarea' : '',
                            ]" v-for="element in list2" :key="element.sid">
                                                        <div class="style-name mr-3">
                                                            {{ element.zdymc ? element.zdymc : element.zdmc }}：
                                                        </div>
                                                        <div class="style-val flexList">
                                                            {{ examineeData[element.zddm] }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </el-col>
                                            <el-col class="guding-img">
                                                <img :src="'data:image/jpeg;base64' +zpdz ? zpdz : defaultZpdz" alt=""
                                                     style="width: 96px; height: 120px"/>
                                            </el-col>
                                            <el-col>
                                                <div style=" width: 99%; flex-wrap: wrap"
                                                     class="flexList fs-xs less-width" :list="list3"
                                                     group="people">
                                                    <div class="flexList msgLabel" :class="[
                              'w' + element.zdbl,
                              element.txlx == 'textarea' ? 'msgtextarea' : '',
                            ]" v-for="element in list3" :key="element.sid">
                                                        <div class="style-name mr-2">
                                                            {{ element.zdymc ? element.zdymc : element.zdmc }}：
                                                        </div>
                                                        <div class="style-val flexList">
                                                            {{ examineeData[element.zddm] }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </el-col>
                                        </el-row>
                                        <el-row>

                                            <table class="table  zkzTable mt-3">
                                                <thead>
                                                <th v-for="obj in list4" :key="obj.sid" :style="{
                              width:
                                (sumWidth && sumWidth != 0
                                  ? (obj.zdbl / sumWidth).toFixed(3) * 100
                                  : obj.zdbl) + '%',
                            }">
                                                    {{ obj.zdmc }}
                                                </th>
                                                </thead>
                                                <tbody v-if="subVals.length">
                                                <tr v-for="(value, index) in subVals" :key="index">
                                                    <td v-for="obj in list4" :key="obj.sid">
                                                        {{ value[obj.zddm] }}
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </el-row>
                                    </div>
                                    <div class="w100  mb-3">
                                        <div class="text-dark  font-size-12" style=" white-space: pre-wrap">
                                            {{ examForm.zkzzysx }}
                                        </div>

                                    </div>
                                    <div class="w-100 code-img">
                                        <img
                                                v-if="ticketData.sfxsewm"
                                                :src="'data:image/jpeg;base64' +qrcode ? qrcode : ''"
                                                alt=""
                                                style="width: 100px!important; height: 100px"
                                        />
                                    </div>
                                </div>
                            </el-col>
                        </el-row>
                        <el-row class="zkzcnsList container" :id="item.mbbm" v-for="(item, i) in zkzcnsInfoList"
                                :key="i">
                            <div class="w100">
                                <div class="gzContent nobgColor">
                                    <p class="text-center font-size-20 text-dark"
                                       style="font-weight: 600; font-size: 16px">
                                        {{ item.mbmc }}
                                    </p>
                                    <div v-html="item.mbnr" class="contentMain"
                                         style="font-family: 微软雅黑,华文细黑,宋体,黑体,arial,Hiragino Sans GB,Microsoft Yahei,Tahoma,Arial,Helvetica,STHeiti"></div>
                                </div>
                            </div>
                        </el-row>
                    </div>
                </div>
                <div class="mt-3 flexList">
                    <!-- <button
                      type="button"
                      class="btn btn-info h30 mr-2"
                      @click="printOrPreview(false)"
                    >
                      打印预览
                    </button> -->
                    <button type="button" class="btn btn-info flexList h30" @click="printOrPreview(true)">
                        <img src="@/assets/images/person/file-pdf.png" class="mr-2"/>下载电子准考证
                    </button>
                </div>
            </div>
        </div>

        <!-- 弹窗开始 -->

        <!-- 弹窗结束-->
    </Layout>
</template>
